/**
 * 运费相关api
 */
import request from "@/utils/request";
import { helper } from "echarts";

/**
 * 获取运费详情
 * @param params
 */
export function getTransPort(params) {
  return request({
    url: '/seller/shops/goodsPostage',
    method: 'get',
    params
  })
}

/**
 * 保存运费设置
 * @param params
 */
export function saveTransPort(params) {
  return request({
    url: '/seller/shops/goodsPostage',
    method: 'put',
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
/**
 * 保存分销设置
 * @param params
 */
export function saveDistribution(data) {
  return request({
    url: '/seller/distribution/V2/gcbrrs/addBatch',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
/**
 * 查询分销设置
 * @param params
 */
export function getDistribution(params) {
  return request({
    url: '/seller/distribution/V2/gcbrrs',
    method: 'get',
    params
  })
}